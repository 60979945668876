import {
  NotificationListDataType,
  NotificationListType,
} from "@/sample/queries/notificationList";
import { FormButtons, NotificationItem } from "@/components";
import { queryVars as API, useCustomMutation } from "@/hooks/utils";
import { Stack } from "@mantine/core";
import { useMemo } from "react";

import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(calendar);

interface INotificationsDrawer {
  notifications?: NotificationListType;
  notificationsLoading: boolean;
  handleCloseDrawer(): void;
}

const getCalendar = (date) => {
  return dayjs(date).calendar(null, {
    sameDay: "[Today]",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd[,] DD/MM/YYYY",
    sameElse: "dddd[,] DD/MM/YYYY",
  });
};

export function NotificationsDrawer({
  notifications,
  notificationsLoading,
  handleCloseDrawer,
}: INotificationsDrawer) {
  const { mutations, keys } = API;
  
  const notificationByDate = useMemo<
    Record<string, Array<NotificationListDataType>>
  >(() => {

    return (
      notifications?.data?.reduce((prev, next) => {
        const date = getCalendar(next.updated);
        prev[date] = (prev[date] ?? []).concat([next]);
        return prev;
      }, {}) ?? {}
    );
  }, [notificationsLoading]);

  const {
    mutate: markAllNotifications,
    isLoading: markAllNotificationsLoading,
  } = useCustomMutation({
    method: "POST",
    url: mutations.markAllNotifications,
    keys: keys.notificationList,
    onSettled: handleCloseDrawer,
    hideSuccess: true,
    hideError: true,
  });

  return (
    <Stack h="100%" pb="sm" spacing="sm">
      <Stack
        px="md"
        className="flex-1 py-4 overflow-auto border-b"
        spacing="xl"
      >
        {Object.entries(notificationByDate).map(([date, items]) => {
          return (
            <Stack key={date} spacing="md">
              <h3 className="text-[#49474D] text-sm font-semibold">{date}</h3>
              <Stack spacing="sm">
                {items?.map((notification) => {
                  const {
                    nid,
                    last_updated_by,
                    message,
                    updated,
                    is_read,
                    category,
                  } = notification;

                  return (
                    <NotificationItem
                      category={category}
                      nid={nid}
                      message={message}
                      name={last_updated_by?.username}
                      time={updated}
                      is_read={is_read}
                      key={nid}
                    />
                  );
                })}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      <FormButtons
        mx="md"
        hideCancel
        submitButtonProps={{
          onClick: () => markAllNotifications(),
          loading: markAllNotificationsLoading,
          disabled: markAllNotificationsLoading,
        }}
        submitText="Mark all as Read"
      />
    </Stack>
  );
}
